import React from 'react'
import * as styles from './Submarine.module.css'
import { Row, Col } from 'react-bootstrap'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
        faWaveformPath, 
        faLocation,
        faAtom,
        faDollarSign,
        faCalendarPlus, 
        faAnchor
    } from "@fortawesome/pro-solid-svg-icons"

    const FutureSection = () => {
        const data = useStaticQuery(graphql`
        {
          videoImage: file(relativePath: { eq: "ard-bayview-poster-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 150, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `)
      return (
        <Row className="my-4">
        <Col>
            <h2 className="text-center">The Future USS IDAHO SSN 799 will have:</h2>
            <Row className="my-4">
                <Col sm={4} className="text-center">
                <div className={`card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faWaveformPath}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Acoustic Stealth</h3>
            <p>which was developed at Acoustic Research Detachment, Bayview Idaho.</p>
            <div className="text-center" style={{width: '100%'}}>
                <div style={{width: '150px', marginLeft: 'auto', marginRight: 'auto'}}>
            <a href="https://www.youtube.com/watch?v=tTc4yHfSNpg&feature=emb_title" target="_blank" rel="noopener noreferrer" >
                <Img fluid={data.videoImage.childImageSharp.fluid} className="rounded" style={{maxWidth: '150px'}}/></a>
                </div>
                </div>
            </div>
                </Col>
                <Col sm={4} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faAtom}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Nuclear Power</h3>
            <p>the original nuclear submarine propulsion system was built at Idaho National Lab in 1953. All of the nuclear training officers and crew from the USS Nautilus SSN 571 trained in Idaho.</p>
            </div>
                </Col>
                <Col sm={4} className="text-center">
                <div className={`display-card-box mb-4 ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faDollarSign}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>An Estimated Cost of $2.6B</h3>
            <p></p>
            </div>
            <div className={`display-card-box ${styles.blueCardBox}`}
            >
                <span> <FontAwesomeIcon
                    icon={faCalendarPlus}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>30+ Year Service Life</h3>
            <p></p>
            </div>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="text-center">
                <div className={`card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faLocation}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Armament</h3>
            <p>12 Vertical Launch Tomahawk Land Attack Missiles</p>
            <p>4 Horizontal Torpedo Tubes capable of launching Mark 48 Advanced Capability Torpedoes and Tomahawk Land Attack Missiles</p>
            </div>
                </Col>
                <Col sm={6} className="text-center">
                <div className={`card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faAnchor}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Special Operations</h3>
            <p>Integrated Lock In / Lock out chamber for Navy SEAL Operations Capability</p>
            </div>
                </Col>
            </Row>
          
                </Col>
    </Row>
      )
    }

    export default FutureSection
