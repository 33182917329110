import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
  Row,
  Col
} from 'react-bootstrap'
import * as styles from './Submarine.module.css'

const COBDavidPope = () => {
  const data = useStaticQuery(graphql`
  {
    CobSkipper: file(relativePath: { eq: "CobSkipper.jpg" }) {
    childImageSharp {
     fluid(maxWidth: 330, quality: 80) {
      ...GatsbyImageSharpFluid
     }
    }
   }
  }
 `)

 return (
  <>
    <Row style={{
      backgroundColor: '#426690',
      borderRadius: '5px',
      padding: '2rem',
    }}>
      <Col sm={9} className={styles.leadershipReverse}>
      <h2>Chief of the Boat</h2>
      <h3>Master Chief Travis Skipper</h3>
      <hr style={{borderColor: 'rgba(255, 255, 255, 0.25)'}}/>
      
    <p>Master Chief Skipper is a native of Greenville, South Carolina. He enlisted in July of 1997 attending Recruit Training at RTC Great Lakes, IL. ETVCM Skipper reported to Submarine School followed by Electronics Technician A-school. In January of 1998, he reported to TRITRAFAC Kings Bay to complete IC Principles and the Strategic Navigation Operator course.  Following graduation, he reported to the USS RHODE ISLAND (SSBN 740) where he completed 8 strategic deterrent patrols and was awarded the Battle Efficiency "E" twice for the ship’s performance between 1999 and 2001.</p>
    <p>Following graduation from SSN Electronics Technician “C” school in 2007 he reported to USS ASHEVILLE (SSN 758) where he served as the Navigation Leading Petty Officer, which saw him through to an advancement to Chief Petty Officer.  During his short time onboard, ASHEVILLE completed a DSRA, Acoustic Trials and ECDIS-N Certification.</p>
    <p>Master Chief Skipper then reported to USS HAMPTON (SSN 767) as the Navigation Leading Chief Petty Officer in late 2007.  While on HAMPTON, he served also as Departmental LCPO, Command DAPA and the Forward Drill Coordinator. During his time aboard, he ship completed a DSRA, Acoustic Trials, ECDIS-N Certification, WESTPAC and CENTCOM deployments and awarded the Battle Efficiency "E" for 2008.</p>
    <p>In December 2014, he reported to the USS OLYMPIA (SSN 717) as the Navigation Department LCPO. He also served as the Supply Department LCPO, the Torpedo Division LCPO, coordinated four CPO transition seasons, completed his Chief of the Boat qualification and graduated from the Senior Enlisted Academy.  During his time onboard, the ship completed a DSRA, Acoustic Trials, 2 WESTPAC deployments and a successful HARPOON launch during a RIMPAC exercise.</p>
    <p>Master Chief Skipper's shore assignments include NAVORDTESTU in Cape Canaveral, FL, an instructor at TRITRAFAC Kings Bay, and Naval Base Point Loma as the Assistant Operation Officer. 
    Master Chief Skipper holds a Bachelor of Arts from Excelsior College, a Masters in Organizational Leadership from the University of Massachusetts, and currently working towards a Doctorate in Strategic Leadership from Liberty University.</p>
    <p>He is married to his wife Cari and they have three children; Travis, Eliana, and Kristena.</p>
    <p>His awards include the Navy and Marine Corps Commendation Medal (4 awards), the Navy and Marine Corps Achievement Medal (10 awards) and the Good Conduct Medal (9 awards).</p>

      </Col>
      <Col md={3}>
        <Img fluid={data.CobSkipper.childImageSharp.fluid} alt="Master Chief Travis Skipper" className="rounded" />
      </Col>
    </Row>
  </>
 )
}

export default COBDavidPope