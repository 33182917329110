import React from "react"

const COBDeanMarvin = (
  <>
    <p>Master Chief Machinist Mate Marvin, a native of Jonesville, Michigan, enlisted in the United States Navy in July 1996. Following completion of basic training at Recruit Training Command, Great Lakes, IL he reported to Naval Submarine School, Groton, CT where be completed Basic Enlisted Submarine School and Machinist's Mate "A" School then transferred to Kings Bay, GA and completed Machinist's Mate "C" school.</p>
    <p>Upon graduation, he reported for duty onboard USS WYOMING (SSBN 742)(B) in Kings Bay, GA completing eight strategic deterrent patrols and various other missions. His additional fleet assignments include USS HAWAII (SSN 776) (2004-2008), USS NORTH CAROLINA (SSN 777) (2008-2011), and USS NEW MEXICO (SSN 779) (2014-2018), completing one SOUTHCOM, one EASTCOM, and two EUCOM deployments. In 2019, he reported to USS NEWPORT NEWS (SSN 750) as the Chief of the Boat, completing one seven-month CENTCOM deployment, a Change of Homeport and a 9-month Docking Selected Restricted Availability. In 2006, he completed Navy Scuba Diver training at Naval Salvage and Diving Training Command, Panama City, FL.</p>
    <p>In 2002, Master Chief Marvin reported to his first shore assignment at Naval Submarine Support Facility, Groton, CT (2002-2004). His additional shore assignments include Performance Monitoring Team, Pearl Harbor, HI (2011-2014) and Naval Submarine School, Groton, CT (2018- 2019) as the Senior Evaluator and Instructor of the Virginia Class Ship's Control Operational Trainer at Naval Submarine School New London.</p>
    <p>Additionally, Master Chief Marvin is a graduate of the U.S. Navy Senior Enlisted Academy Class 214, COB/CMC School Class 203, and earned his Enlisted Submarine Warfare and Navy Scuba Diver Insignia. In August 2019, he relieved as Chief of the Boat onboard USS NEWPORT NEWS.</p>
    <p>His decorations include the Meritorious Service Medal, Navy and Marine Corps Commendation Medal (three Awards), Navy and Marine Corps Achievement Medal (10 Awards), and various unit and campaign awards.</p>
    <p>Master Chief Marvin is married with seven children and lives in Salem, CT.</p>
  </>
)

export default COBDeanMarvin
