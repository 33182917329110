import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'

const SubmarineSidebar = () => (
    <div className="card-box">
        <h1>PCU Idaho Submarine Menu</h1>
        <Nav>
        <Link
                to="/pcu-idaho-submarine#leadership"
                eventKey="5.1"
                className="dropdown-item nav-link"
              >
                Leadership
              </Link>
              <Link
               
                eventKey="5.2"
                className="dropdown-item nav-link"
                to="/pcu-idaho-submarine#sponsor"
              >
                Sponsor
              </Link>

              <Link
              
                className="dropdown-item nav-link"
                eventKey="5.3"
                to="/pcu-idaho-submarine#crew"
              >
                Crew
              </Link>
              <Link
              
                className="dropdown-item nav-link"
                eventKey="5.4"
                to="/pcu-idaho-submarine#ships-crest"
              >
                Crest
              </Link>
              <div class="dropdown-header" style={{
                paddingleft: '5px !important'
              }}>The Boat</div>
              <Link
              
                className="dropdown-item nav-link"
                eventKey="5.5"
                to="/pcu-idaho-submarine#boat-capabilities"
              >
                Capabilities
              </Link>
              <Link
              
                className="dropdown-item nav-link"
                eventKey="5.5"
                to="/pcu-idaho-submarine#boat-mission"
              >
                Missions
              </Link>
        </Nav>
    </div>
)

export default SubmarineSidebar