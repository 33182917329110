import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'react-bootstrap'

const CapabilitiesSection = () => {
    const data = useStaticQuery(graphql`
    {
      subGraphic: file(relativePath: { eq: "virginia-class-diagram.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1502, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <>
    <Row className="mb-4">
    <Col className="mx-auto d-block">
        <Img fluid={data.subGraphic.childImageSharp.fluid} alt="Virginia Class Submarine Graphic" />
    </Col>
    </Row>
    </>
  )
}

export default CapabilitiesSection