import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
    Row,
    Col
} from 'react-bootstrap'
import * as styles from './Submarine.module.css'

const XOMedrano = () => {
    const data = useStaticQuery(graphql`
    {
      Xo: file(relativePath: { eq: "DeVillar-Justin_LCDR.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 330, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
        <Row style={{
            backgroundColor: 'white',
            borderRadius: '5px',
            padding: '2rem',
        }}>
            <Col sm={9} className={styles.leadership}>
            <h2>Executive Officer</h2>
            <h3>Lieutenant Commander Justin DeVillar</h3>
            <hr style={{borderColor: 'rgba(0, 0, 0, 0.25)'}}/>
            <p>LCDR DeVillar earned a Bachelor of Science in Computer Science and Electrical Engineering from the United States Naval Academy in 2010. As a Bowman scholar he earned a Masters of Science in Electrical Engineering from the Naval Postgraduate School in 2011.</p>
            <p>LCDR DeVillar served as Engineer Officer on USS CALIFORNIA (SSN-781) and as a Division Officer on USS JIMMY CARTER (SSN-23). LCDR DeVillar’s shore assignments included Staff Training Officer and Shift Engineer on the MARF Prototype at Nuclear Power Training Unit (NPTU) Ballston Spa, NY and Engineer on Moored Training Ship 626 DANIEL WEBSTER at NPTU Charleston, SC.</p>
            <p>LCDR DeVillar and his wife reside in Groton, Connecticut with their two children.</p>
            </Col>
            <Col md={3}>
                <Img fluid={data.Xo.childImageSharp.fluid} alt="Lieutenant Commander Justin DeVillar" className="rounded" />
            </Col>
        </Row>
    </>
  )
}

export default XOMedrano