import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
    Row,
    Col
} from 'react-bootstrap'
import * as styles from './Submarine.module.css'

const CORandallLeslie = () => {
    const data = useStaticQuery(graphql`
    {
      RandallLeslie: file(relativePath: { eq: "RandallLeslie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 330, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
        <Row style={{
            backgroundColor: '#426690',
            borderRadius: '5px',
            padding: '2rem',
        }}>
            <Col sm={9} className={styles.leadershipReverse}>
            <h2>Commanding Officer</h2>
            <h3>Commander Randall Leslie</h3>
            <hr style={{borderColor: 'rgba(255, 255, 255, 0.25)'}}/>
            
            <p>
    A native of Highland, CA, CDR Leslie graduated from the U.S. Naval Academy in 2004 with a Bachelor of Science in Mechanical Engineering. After completing his initial nuclear power and submarine training, he served as a division officer onboard USS BUFFALO (SSN 715). Following his division officer tour CDR Leslie next obtained his Masters Degree in Nuclear Engineering from the University of Maryland and taught in the Mechanical Engineering Department at the U.S. Naval Academy.
    </p>
    <p>Following graduation from the Submarine Officer Advanced Course he served as the Navigator onboard USS ASHEVILLE (SSN 758). After his tour as Navigator CDR Leslie served at the Department of State in Washington DC in the Officer of Nuclear Energy, Safety, and Security and in the Office of Japanese Affairs. During this tour he obtained a Masters Degree in International Policy and Practice from George Washington University.</p>
    <p>After Completing the Submarine Command Course, CDR Leslie reported as Executive Officer onboard USS KENTUCKY (SSBN 737)(BLUE). During his tour on KENTUCKY the ship completed two strategic deterrent patrols and was awarded the Battle "E". After his Executive Officer tour CDR Leslie reported to Chief of Naval Operations Assessment Division (OPNAV N81) at the Pentagon as a munitions analyst.
      </p>
      <p>CDR Leslie is now privileged to serve alongside the world's greatest sailors as Commanding Officer of PCU IDAHO (SSN 799).
    </p>

            </Col>
            <Col md={3}>
                <Img fluid={data.RandallLeslie.childImageSharp.fluid} alt="Commander Randall Leslie" className="rounded" />
            </Col>
        </Row>
    </>
  )
}

export default CORandallLeslie