import React from 'react'
import * as styles from './Submarine.module.css'
import { Row, Col } from 'react-bootstrap'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import ModalImage from 'react-modal-image'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
        faWater,
        faMountains,
        faBinoculars,
        faShip,
        faLocation,
        faRobot,
        faAtom,
        faDollarSign,
        faCalendarPlus, 
        faAnchor
    } from "@fortawesome/pro-solid-svg-icons"

    const MissionsSection = () => {
        const data = useStaticQuery(graphql`
        {
          videoImage: file(relativePath: { eq: "ard-bayview-poster-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 150, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          seaControl1: file(relativePath: { eq: "sea-control-1.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          seaControl2: file(relativePath: { eq: "sea-control-2.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          seaControl3: file(relativePath: { eq: "sea-control-3.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          seaControl4: file(relativePath: { eq: "sea-control-4.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          landAttack1: file(relativePath: { eq: "land-attack-1.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          landAttack2: file(relativePath: { eq: "land-attack-2.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          landAttack3: file(relativePath: { eq: "land-attack-3.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          specialOps1: file(relativePath: { eq: "special-ops-1.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          specialOps2: file(relativePath: { eq: "special-ops-2.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          seaWolf: file(relativePath: { eq: "USS_Seawolf_with_carrier.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          battleGroup: file(relativePath: { eq: "carrier-battle-group.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          antiSub: file(relativePath: { eq: "anti-submarine.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          underseaMines: file(relativePath: { eq: "undersea-mines.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          mineDetection: file(relativePath: { eq: "mine-detection.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          missile: file(relativePath: { eq: "missile.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          explosion: file(relativePath: { eq: "explosion.jpg" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
          isr: file(relativePath: { eq: "ISR.png" }) {
            childImageSharp {
              fluid(quality: 80) {
                srcWebp
              }
            }
          }
        }
      `)
      return (
<>
            <Row className="my-4">
                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faWater}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Sea Control and Freedom of Navigation Operations</h3>
                <Row>
                    <Col sm={3}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.seaControl1.childImageSharp.fluid.srcWebp}
            large={data.seaControl1.childImageSharp.fluid.srcWebp}
            alt="Sea Control 1"
            className="rounded"
           />
                    </Col>
                    <Col sm={3}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.seaControl2.childImageSharp.fluid.srcWebp}
            large={data.seaControl2.childImageSharp.fluid.srcWebp}
            alt="Sea Control 2"
            className="rounded"
           />
                    </Col>
                    <Col sm={3}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.seaControl3.childImageSharp.fluid.srcWebp}
            large={data.seaControl3.childImageSharp.fluid.srcWebp}
            alt="Sea Control 3"
            className="rounded"
           />
                    </Col>
                    <Col sm={3}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.seaControl4.childImageSharp.fluid.srcWebp}
            large={data.seaControl4.childImageSharp.fluid.srcWebp}
            alt="Sea Control 4"
            className="rounded"
           />
                    </Col>
                </Row>
            
 
            </div>
                </Col>
                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faMountains}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Land Attack</h3>
            <Row>
            <Col sm={4}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.landAttack1.childImageSharp.fluid.srcWebp}
            large={data.landAttack1.childImageSharp.fluid.srcWebp}
            alt="Land Attack 1"
            className="rounded"
           />
                    </Col>
                    <Col sm={4}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.landAttack2.childImageSharp.fluid.srcWebp}
            large={data.landAttack2.childImageSharp.fluid.srcWebp}
            alt="Land Attack 1"
            className="rounded"
           />
                    </Col>
                    <Col sm={4}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.landAttack3.childImageSharp.fluid.srcWebp}
            large={data.landAttack3.childImageSharp.fluid.srcWebp}
            alt="Land Attack 1"
            className="rounded"
           />
                    </Col>
            </Row>
            </div>
                </Col>
                {/* <Col sm={4} className="text-center">
                <div className={`display-card-box mb-4 ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faDollarSign}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>An Estimated Cost of $2.6B</h3>
            <p></p>
            </div>
            <div className={`display-card-box ${styles.blueCardBox}`}
            >
                <span> <FontAwesomeIcon
                    icon={faCalendarPlus}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>30+ Year Service Life</h3>
            <p></p>
            </div>
                </Col> */}
            </Row>
            <Row>
                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faBinoculars}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Anti Submarine Warfare</h3>
                <Row>
                  <Col>
                  <ModalImage
            hideDownload
            hideZoom 
            small={data.antiSub.childImageSharp.fluid.srcWebp}
            large={data.antiSub.childImageSharp.fluid.srcWebp}
            alt="Carrier/Task Force Battle Group Escort"
            className="rounded"
           />
                  </Col>
                </Row>

            </div>
                </Col>

                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faShip}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Carrier/Task Force Battle Group Escort</h3>
                <Row>
            <Col sm={6}>
                <ModalImage
            hideDownload
            hideZoom 
            small={data.seaWolf.childImageSharp.fluid.srcWebp}
            large={data.seaWolf.childImageSharp.fluid.srcWebp}
            alt="Carrier/Task Force Battle Group Escort"
            className="rounded"
           />
           </Col>
           <Col sm={6}>
           <ModalImage
            hideDownload
            hideZoom 
            small={data.battleGroup.childImageSharp.fluid.srcWebp}
            large={data.battleGroup.childImageSharp.fluid.srcWebp}
            alt="Carrier/Task Force Battle Group Escort"
            className="rounded"
           />
           </Col>
           </Row>
            </div>
                </Col>
            </Row>
            <Row className="my-4">
                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faLocation}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Mine Laying and Detection</h3>
                <Row>
                  <Col>
                  <ModalImage
            hideDownload
            hideZoom 
            small={data.underseaMines.childImageSharp.fluid.srcWebp}
            large={data.underseaMines.childImageSharp.fluid.srcWebp}
            alt="Special Operations 1"
            className="rounded"
           />
                  </Col>
                  <Col>
                  <ModalImage
            hideDownload
            hideZoom 
            small={data.mineDetection.childImageSharp.fluid.srcWebp}
            large={data.mineDetection.childImageSharp.fluid.srcWebp}
            alt="Special Operations 1"
            className="rounded"
           />
                  </Col>
                </Row>
            </div>
                </Col>
                <Col sm={6} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faAnchor}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Special Operations Control/Support</h3>
                <Row>
                <Col sm={6}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.specialOps1.childImageSharp.fluid.srcWebp}
            large={data.specialOps1.childImageSharp.fluid.srcWebp}
            alt="Special Operations 1"
            className="rounded"
           />
                    </Col>
                    <Col sm={6}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.specialOps2.childImageSharp.fluid.srcWebp}
            large={data.specialOps2.childImageSharp.fluid.srcWebp}
            alt="Special Operations 2"
            className="rounded"
           />
                    </Col>
                </Row>
            </div>
                </Col>
            </Row>
            <Row className="my-4">
            <Col sm={4} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}
                    style={{
                        height: '100%'
                    }}
                >
                <span> <FontAwesomeIcon
                    icon={faAnchor}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Anti Surface Ship Warfare</h3>
                <Row>
                <Col sm={6}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.missile.childImageSharp.fluid.srcWebp}
            large={data.missile.childImageSharp.fluid.srcWebp}
            alt="Anti Surface Ship Warfare"
            className="rounded"
           />
                    </Col>
                    <Col sm={6}>
                    <ModalImage
            hideDownload
            hideZoom 
            small={data.explosion.childImageSharp.fluid.srcWebp}
            large={data.explosion.childImageSharp.fluid.srcWebp}
            alt="Anti Surface Ship Warfare"
            className="rounded"
           />
                    </Col>
                </Row>
            </div>
                </Col>
              <Col sm={4} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faBinoculars}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Intelligence, Surveillance &amp; Reconnaissance</h3>
                <ModalImage
            hideDownload
            hideZoom 
            small={data.isr.childImageSharp.fluid.srcWebp}
            large={data.isr.childImageSharp.fluid.srcWebp}
            alt="Anti Surface Ship Warfare"
            className="rounded"
           />
            </div>
                </Col>
                <Col sm={4} className="text-center">
                <div className={`display-card-box ${styles.blueCardBox}`}>
                <span> <FontAwesomeIcon
                    icon={faRobot}
                    style={{
                        fontSize: '3rem',
                        marginRight: '5px',
                        color: '#003366'
                    }}
                    aria-hidden="true"
                    
                /></span><h3 className={styles.cardTitle}>Launch/Recovery of Unmanned Undersea Vehicles</h3>
            </div>
                </Col>
            </Row>
          </>

      )
    }

    export default MissionsSection
