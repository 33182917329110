import React from "react"

const XOReneMedrano = (
  <>
    <p>Rene Medrano is the Executive Officer of the PCU IDAHO (SSN-799).</p>
    <p>LCDR Medrano earned a Bachelor of Science in Nuclear Engineering from Virginia Tech.  He also earned a Masters degree in Applied Physics from Naval Postgraduate School in Monterey, California.</p>
    <p>During an initial sea tour aboard USS WYOMING (SSBN 742), homeported in Kings Bay, GA. There he served as the Chemistry and Radiological Controls Assistant and Tactical Systems Officer, and completed 4 Strategic Deterrent Patrols.</p>
    <p>Later, as Combat Systems Officer aboard USS JOHN WARNER (SSN 785), LCDR Medrano completed initial Sea Trials and executed all major milestones through Commissioning and the ship’s first Tactical Readiness Exam.  Due to manning issues aboard the USS Norfolk, he also volunteered to serve with her during a CENTCOM deployment.</p>
    <p>Ashore, Rene was a Regional Employment Officer assigned to the operations staff of Commander Submarine Forces Atlantic.</p>
    <p>Most recently, LCDR Medrano served aboard PCU NEW JERSEY (SSN 796) as the Forward Department Head.  There he helped establish the new command and support preparations for their first major milestone, initial fill.</p>
    <p>His wife, Valerie, still resides in Carrollton, Virginia with their son Aidan (age 17).  His daughter Alexandra (age 22) maintains her own residence in Newport News, Virginia.</p>
  </>
)

export default XOReneMedrano
