import React from 'react'
import { Row, Col } from 'react-bootstrap'

import CORandallLeslie from './CORandallLeslie'
import DeanMarvin from './COBDeanMarvin'
import XOMedrano from './XOMedrano'
import XODarrellSmith from './XODarrellSmith'
import COBTravisSkipper from './COBTravisSkipper'

const CrewSection = () => (
    <>
    <CORandallLeslie />
    <XODarrellSmith />
    <COBTravisSkipper />
</>
)

export default CrewSection